.counter{
    &__wrapper{
        display: flex;
        justify-content: space-around;

        margin-bottom: 30px;
        margin-top: -20px;
    }
    &__box{
        display: flex;
        flex-direction: column;
        align-items: center;

        width: 20%;
        height: 90px;

        padding: 10px 40px;

        border: 1px solid rgba(97, 99, 100, 0.18);
        border-radius: 15px;

        box-shadow: 0 1px 1px hsl(0deg 0% 0% / 0.075), 
                    0 2px 2px hsl(0deg 0% 0% / 0.075), 
                    0 4px 4px hsl(0deg 0% 0% / 0.075), 
                    0 8px 8px hsl(0deg 0% 0% / 0.075), 
                    0 16px 16px hsl(0deg 0% 0% / 0.075);
    }
    &__text{
        font-size: 20px;
        color: rgb(84, 95, 100);
    }
    &__number{
        font-size: 40px;
        font-weight: 500;
        font-family: "Roboto",sans-serif;
        color: rgb(84, 95, 100);
    }
}

.main-choose{
    display: flex;
    justify-content: space-evenly;
    margin-top: 120px;
}

.main-column{
    width: 25%;
    padding: 20px 20px;

    border: 1px solid rgba(97, 99, 100, 0.18);
    border-radius: 15px;

    box-shadow: 0 1px 1px hsl(0deg 0% 0% / 0.075), 
                0 2px 2px hsl(0deg 0% 0% / 0.075), 
                0 4px 4px hsl(0deg 0% 0% / 0.075), 
                0 8px 8px hsl(0deg 0% 0% / 0.075), 
                0 16px 16px hsl(0deg 0% 0% / 0.075);

    
    font-weight: 500;
    font-family: "Roboto",sans-serif;
    color: #545f64;

    &:hover{
        background-color: #107ae555;
        transition: .5s
    }

    & h2{
        font-size: 40px;
        margin-bottom: 10px;
    }

    & h3{
        font-size: 24px;
    }

    &__link{
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    &__image{
        width: 100%;
        margin-bottom: 20px;
    }
}