.send-inputs-wrapper{
    width: 1000px;
    display: flex;
}

.send-inputs-column{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
    
    &__left{
        width: 400px;
        margin-right: 40px;
    }
}
.inputs-halfwidth-column{
    width: 49%;
}
.inputs-halfwidth-column:nth-of-type(1){
    margin-right: 2%;
}
.send-inputs-row{
    display: flex;
    margin-bottom: 60px;

    &__underline{
        position: relative;
        display: flex;

        justify-content: space-between;

            &::after{
                content: '';
                position: absolute;
                width: 100%;
                height: 2px;
                background-color: #0006;
                top: 85px;
            }
    }
}

.time-end{
    width: 275px;
}

.direct-button{
    width: 160px;
    height: 56px;
}


