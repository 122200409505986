.top-block {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: 15px;
    &__left{
        display: flex;
        align-items: center;
    }
}

.placeholder {
    flex-grow: 1;
}