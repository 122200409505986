.inputs-wrapper{
    width: 600px;
    display: flex;
    justify-content: space-between;
}

.inputs-wrapper.right-aligned-textfield {
    margin-left: auto;
    justify-content: flex-end;
}

.custom-input{
    width: 236px;
}