.reports{
    &__wrapper{
        display: flex;
    }
    &__btn-wrapper{
        height: 56px;
        margin-top: 20px;
    }
    &__btn{
        height: 56px;
    }
}