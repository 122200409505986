.main-nav{
    margin-bottom: 50px;
}

.main-nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.main-nav .logout-button-wrapper {
    margin-left: auto;
}

.username{
    margin-right: 25px;
    color:rgb(84, 95, 100);
}