.counter {
    &__wrapper {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
      margin-bottom: 30px;
    }
    &__table {
      width: 47%; // Размер каждой таблицы, чтобы вместить две в ряд
      margin-bottom: 40px;
      border: 2px solid rgba(97, 99, 100, 0.565);
      border-radius: 15px;
    //   box-shadow: 0 1px 1px hsl(0deg 0% 0% / 0.075), 
    //               0 2px 2px hsl(0deg 0% 0% / 0.075), 
    //               0 4px 4px hsl(0deg 0% 0% / 0.075), 
    //               0 8px 8px hsl(0deg 0% 0% / 0.075), 
    //               0 16px 16px hsl(0deg 0% 0% / 0.075);
      border-collapse: collapse;
  
      th, td {
        text-align: center;
        padding: 10px;
        border: 1px solid rgba(97, 99, 100, 0.18);
        min-width: 120px;
      }
      th {
        background-color: #f4f4f4;
        font-size: 20px;
        color: rgb(84, 95, 100);
      }
      td {
        font-size: 30px;
        font-weight: 500;
        font-family: "Roboto", sans-serif;
        color: rgb(84, 95, 100);
      }
      tr:nth-child(even) {
        background-color: #f9f9f9;
      }
    }
  }
  
