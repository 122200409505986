.counter{
    &__wrapper{
        position: relative;

        display: flex;
        justify-content: space-around;

        margin-bottom: 30px;
        margin-top: -20px;
    }
    &__box{
        display: flex;
        flex-direction: column;
        align-items: center;

        width: 20%;
        height: 90px;

        padding: 10px 40px;

        border: 1px solid rgba(97, 99, 100, 0.18);
        border-radius: 15px;

        box-shadow: 0 1px 1px hsl(0deg 0% 0% / 0.075), 
                    0 2px 2px hsl(0deg 0% 0% / 0.075), 
                    0 4px 4px hsl(0deg 0% 0% / 0.075), 
                    0 8px 8px hsl(0deg 0% 0% / 0.075), 
                    0 16px 16px hsl(0deg 0% 0% / 0.075);
    }
    &__text{
        font-size: 20px;
        color: rgb(84, 95, 100);
    }
    &__number{
        font-size: 40px;
        font-weight: 500;
        font-family: "Roboto",sans-serif;
        color: rgb(84, 95, 100);
    }
    &__test{
        position: relative;

        margin-bottom: 30px;
        margin-top: -20px;
    }
}



.popup{
    &__wrapper {
        position: absolute;
        bottom: 100px;
        left: 0;
        width: 50%;
        background-color: rgb(211, 211, 211);
        border: 1px solid #ddd;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
        z-index: 100;
    }
    &__box {
        padding: 10px;
        display: flex;
        align-items: center;
        justify-content: start;
    }
    &__image {
        width: 40px;
        height: auto;
        margin-right: 10px;
    }
    &__received {
        margin-right: 10px;
    }
    &__country {
        margin-right: 10px;
    }
}