.dropdown{
    &__container{
        display: flex;
        align-items: center;
        margin-bottom: 15px;
    }
    &__button{
        height: 56px;
        width: 80px;
        font-size: 1.1rem;
    }
    &__box{
        margin-right: 40px;
        margin-left: 40px;
    }
}